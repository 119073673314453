const skillDataSet = [
  {
    title: 'Javascript',
    desc: '工作上以 Angular 和 Vue3 開發銀行核心系統。',
    contents: ['Angular', 'Vue', 'React 18', 'TypeScript', 'jQuery'],
  },
  {
    title: 'CSS',
    desc: '擅長使用 Bootstrap 快速客製具有 RWD 的使用者介面。',
    contents: ['Tailwind', 'Bootstrap', 'Sass', 'Responsive Web'],
  },
  {
    title: 'Server-End',
    desc: '工作上以 JAVA 開發銀行核心業務邏輯。',
    contents: ['JAVA', 'Spring Boot', 'PHP', 'Laravel'],
  },
  {
    title: 'Data Visualization',
    desc: '學生時期常使用各種資料視覺工具打造 Dahsboard。',
    contents: ['Google Charts', 'D3.js', 'Ploty.js', 'Highcharts.js'],
  },
  {
    title: 'Design',
    desc: '學生時期常製作社團文宣。',
    contents: ['illustrator', 'PhotoImpact'],
  },
  {
    title: 'Video',
    desc: '學生時期常剪輯遊戲影片。',
    contents: ['Sony Vegas'],
  },
];

export default skillDataSet;
